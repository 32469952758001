// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterEdit_footer__Ef4mY {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FooterEdit_bottom__J9G5A {
  background-color: #ededed;
  padding-top: 20px;
  width: 100%;
  height: 500px;
}

.FooterEdit_editBox__Up2Gi {
  margin: 0 30px;
  display: flex;
}

.FooterEdit_partnerBox__UHccF {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-left: 20px;
}

.FooterEdit_saveBtn__IbPZF {
  width: 150px;
  margin: 10px;
  border-radius: 10px;
  background-color: #eb1749;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.FooterEdit_saveBtn__IbPZF:hover {
  border: 1px solid #eb1749;
  background: #fff;
  color: #eb1749;
}`, "",{"version":3,"sources":["webpack://./src/pages/FooterEditingPages/FooterEdit.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".footer {\n  padding-top: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.bottom {\n  background-color: #ededed;\n  padding-top: 20px;\n  width: 100%;\n  height: 500px;\n}\n\n.editBox {\n  margin: 0 30px;\n  display: flex;\n}\n\n.partnerBox {\n  width: 60%;\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n  margin-left: 20px;\n}\n\n.saveBtn {\n  width: 150px;\n  margin: 10px;\n  border-radius: 10px;\n  background-color: #eb1749;\n  color: #fff;\n  font-size: 20px;\n  font-weight: 700;\n}\n\n.saveBtn:hover {\n  border: 1px solid #eb1749;\n  background: #fff;\n  color: #eb1749;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `FooterEdit_footer__Ef4mY`,
	"bottom": `FooterEdit_bottom__J9G5A`,
	"editBox": `FooterEdit_editBox__Up2Gi`,
	"partnerBox": `FooterEdit_partnerBox__UHccF`,
	"saveBtn": `FooterEdit_saveBtn__IbPZF`
};
export default ___CSS_LOADER_EXPORT___;
