import axios from "axios";
import { getPartnerInfo } from "./FooterEdit";
import { Cookies, useCookies } from "react-cookie";

export const PartnerTableCloumns = (setAllPartner) => [
    {
        Header: "Actions",
        Cell: ({ row }) => {
            // const partnerId = row.original.partner_db_id;
            const partnerId = row.original.id;
            
            const [cookies, setCookie, removeCookie] = useCookies(["id"]);

            const handleDeletePartner = () => {
                const confirmDelete = window.confirm("삭제하시겠습니까?");
                if (confirmDelete) {
                    axios
                        .delete(`${process.env.REACT_APP_AMUSE_API}/api/v1/partners?partner-id=${partnerId}`, {
                            // headers: {
                            //     "Content-Type": "application/json",
                            //     Authorization: cookies.id,
                            // },
                        })
                        .then((response) => {
                            console.log(response);
                            getPartnerInfo(setAllPartner);
                        })
                        .catch((error) => console.log(error));
                }
            };

            return <button onClick={() => handleDeletePartner(partnerId)}>삭제하기</button>;
        },
    },
    {
        Header: "No.",
        accessor: "id",
    },
    {
        Header: "이름",
        accessor: "name",
    },
    {
        Header: "홈페이지 주소",
        accessor: "link",
    },
    {
        Header: "로고 이미지",
        accessor: "img",
    },
];
