import React, {useEffect, useState} from "react";
import Table from "../../../components/Table/Table";
import styles from './Terms.module.css'
import { TermsLogic } from '../../../logics/TermsLogic';
import {useNavigate} from "react-router-dom";

// To Extract
// API 확정되면, Extract
const TermsTableColumns = [
	{
		Header: 'ID',
		accessor: 'id'
	},
	{
		Header: '타입',
		accessor: 'type'
	},
	{
		Header: '내용',
		accessor: 'content'
	},
];

const Terms = () => {
	
	const [termsList, setTermsList] = useState<any>([]);
	const [pageCount, setPageCount] = useState(1);

  const navigate = useNavigate();
	
	useEffect(() => {
		
		(async () => {
			const response  = await TermsLogic.getComponentList();
			setTermsList(response);
			setPageCount(Math.ceil(response.length / 10));
			console.log(Math.ceil(response.length / 10));
		})()
		
	}, []);
	
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h2> 약관 동의 관리 (*이 페이지는 구현 중이여서 아직 사용 불가능합니다. 결제, 환불 약관은 사용 가능) </h2>

        		<div>
					<button className={styles.button}
							onClick={() => navigate("/rules/terms/create")}
					>
						생성하기
					</button>
				</div>
			</div>
			
			<div style={{paddingTop: 30}}>
				<Table route={"terms"} columns={TermsTableColumns} data={termsList} pageCount={pageCount}/>
			</div>
		
		</div>
	)
}

export default Terms
