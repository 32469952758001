import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import GuideModal from "./GuideModal";
import GuideModalStyles from "./GuideModal.module.css";
import axios from "axios";
// import styles from "./StaffDetail.module.css";
import Table from "../../components/Table/Table";
import { GuideTableCloumns } from "../../components/Table/GuideTableColumns";
import { useCookies } from "react-cookie";

export const getGuideInfo = (setAllGuide) => {
  axios
    .get(`${process.env.REACT_APP_AMUSE_API}/test/api/list/guide?page=1&limit=200`)
    .then((res) => {
      console.log(res);
      setAllGuide(res.data.data.guideInfo);
    })
    .catch((err) => console.log(err));
};

const StaffDetail = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["id"]);
  const [allGuide, setAllGuide] = useState();
  const [isEditGuide , setIsEditGuide] = useState(false);
  const [isEditGuideInfo , setIsEditGuideInfo] = useState({
      "guideDbId": null,
      "guideCode": "",
      "userName": "",
      "email": "",
      "profileImageUrl": "",
      "base64Data":"",
      "introduce": ""
  });
  const [guideName,setGuideName] = useState("")
  const [guideEmail,setGuideEmail] = useState("")
  const [guideCode,setGuideCode] = useState("")
  const [guideIntroduction,setGuideIntroduction] = useState("")
  const [guideImg, setGuideImg] = useState();
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    getGuideInfo(setAllGuide);
  }, []);
  const postUpdateGuide = (guideId) => {
    const confirmDelete = window.confirm("수정하시겠습니까?");
    if (confirmDelete) {
      isEditGuideInfo.name = guideName
      isEditGuideInfo.fileName = fileName
      isEditGuideInfo.base64Data = guideImg
      axios
        .post(`${process.env.REACT_APP_AMUSE_API}/test/api/update/guide/${guideId}`,isEditGuideInfo, {
          headers: {
            "Content-Type": "application/json",
            Authorization: cookies.id,
          },
        })
        .then((res) => {
          console.log(res);
          getGuideInfo(setAllGuide);
        })
        .catch((err) => console.log(err))
        .finally(()=>{setIsEditGuide(false)})
        
    }
    console.log(isEditGuideInfo)
  };
  const editModalHandle =(state)=>{
    setIsEditGuide(state)
  }
  const editEditGuideInfo =(info)=>{
    setIsEditGuideInfo(info)
    setGuideName(info.userName)
    setGuideEmail(info.email)
    setGuideCode(info.guideCode)
    setGuideIntroduction(info.introduce)
    setGuideImg(info.profileImageUrl)
  }
  const editGuideName = (guideName) =>{
    let state = isEditGuideInfo
    state.name=guideName
    console.log(guideName)
    setIsEditGuideInfo(state)
    setGuideName(guideName)
  }
  const editGuideEmail = (guideEmail) =>{
    let state = isEditGuideInfo
    state.email=(guideEmail)
    setIsEditGuideInfo(state)
    setGuideEmail(guideEmail)
  }
  const editGuideCode= (guideCode) =>{
    let state = isEditGuideInfo
    state.guideCode=(guideCode)
    setIsEditGuideInfo(state)
    setGuideCode(guideCode)
  }
  const editGuideIntroduction = (guideIntroduction) =>{
    let state = isEditGuideInfo
    state.introduce=(guideIntroduction)
    setIsEditGuideInfo(state)
    setGuideIntroduction(guideIntroduction)
  }
  const handleFileChange=(e)=>{
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setGuideImg(event.target.result);
      };
      reader.readAsDataURL(file);
      setFileName(file.name);
    }
  }

  return (
    <div style={{ margin: "18px", position:"relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #eb1749",
          margin: "30px 50px 0px 50px",
          paddingBottom: 10,
        }}
      >
        <h2> 가이드 관리 </h2>
        <GuideModal getGuideInfo={getGuideInfo} setAllGuide={setAllGuide} />
      </div>
      <div style={{ marginTop: "30px" }}>
        {allGuide && allGuide.length > 0 ? (
          <Table columns={GuideTableCloumns(setAllGuide,editModalHandle,editEditGuideInfo)} data={allGuide} />
        ) : (
          <></>
        )}
      </div>
      {isEditGuide ?
        <div className={GuideModalStyles.modal} >
          <div  className={GuideModalStyles.modalContent}>
            <div>
              <div style={{display:"flex",flexDirection:"column"}}>
                <img
                  src={guideImg}
                  alt="Selected"
                  className={GuideModalStyles.previewImage}
                  style={{ height: "150px" ,objectFit:"contain" }}
                />
                <input type="file" accept="image/*" onChange={handleFileChange} />
              </div>
              <div style={{display:"flex",flexDirection:"column"}}>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginLeft:24,marginRight:24}}>
                  <div style={{width:48}}>이름</div>
                  <input value={guideName} onChange={(e) => editGuideName(e.target.value)} placeholder="이름" />
                </div>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginLeft:24,marginRight:24}}>
                  <div style={{width:48}}>이메일</div>
                  <input value={guideEmail} onChange={(e) => editGuideEmail(e.target.value)} placeholder="이메일" />
                </div>
                <div>
              </div>
              <textarea
                value={guideIntroduction}
                onChange={(e) => editGuideIntroduction(e.target.value)}
                placeholder="직원 소개"
              />
            </div>

            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
              <div style={{cursor:"pointer"}} onClick={()=>{postUpdateGuide(isEditGuideInfo.guideDbId)}}>수정하기</div>
              <div style={{cursor:"pointer"}} onClick={()=>{editModalHandle(false)}}>close</div>
            </div>
          </div>
        </div>
        :<></>
      }
    </div>
  );
};

export default StaffDetail;
