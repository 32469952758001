// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagerDetail_guideWrap__Rtx2I {
  display: flex;
  margin: 20px;
}

.ManagerDetail_guideInfo__iCGDc {
  font-size: 14px;
  margin: 4px 12px;
}

.ManagerDetail_guideBtn__6sFTr {
  background: #eb1749;
  width: 80px;
  height: 30px;
  color: #fff;
  border-radius: 10px;
}

.ManagerDetail_guideBtn__6sFTr {
  background: #eb1749;
  width: 100px;
  height: 30px;
  color: #fff;
  border-radius: 10px;
}

.ManagerDetail_guideBtn__6sFTr:hover {
  border: 1px solid #eb1749;
  background: #fff;
  color: #eb1749;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagerPages/ManagerDetail.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".guideWrap {\n  display: flex;\n  margin: 20px;\n}\n\n.guideInfo {\n  font-size: 14px;\n  margin: 4px 12px;\n}\n\n.guideBtn {\n  background: #eb1749;\n  width: 80px;\n  height: 30px;\n  color: #fff;\n  border-radius: 10px;\n}\n\n.guideBtn {\n  background: #eb1749;\n  width: 100px;\n  height: 30px;\n  color: #fff;\n  border-radius: 10px;\n}\n\n.guideBtn:hover {\n  border: 1px solid #eb1749;\n  background: #fff;\n  color: #eb1749;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guideWrap": `ManagerDetail_guideWrap__Rtx2I`,
	"guideInfo": `ManagerDetail_guideInfo__iCGDc`,
	"guideBtn": `ManagerDetail_guideBtn__6sFTr`
};
export default ___CSS_LOADER_EXPORT___;
