// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Refund_container__hqymU {
    display: flex;
    flex-direction: column;
}

.Refund_header__F2nM6{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eb1749;
    margin: 30px 50px 0px 50px;
    padding-bottom: 10px;
}

.Refund_body__3Dyyt{
    display: flex;
    width: 98%;
    padding: 0 30px 0 30px;
    flex-direction: column;
    border: 1px solid #eb1749;
    border-radius: 0.5rem;
    margin: 10px 1% 10px 1%;
}

.Refund_p__oumgL{
    margin: 20px 0 20px 0;
}

.Refund_pTitle__h9UQ0{
    margin-bottom: 10px;
}

.Refund_searchTextInput__hwaYD{
    border: 1px solid;
}

.Refund_searchTextInput__hwaYD:focus-visible{
    border: 1px solid;
}


.Refund_textInput__ve\\+-3{
    width: 250px;
    border: 1px solid;
}

.Refund_textInput__ve\\+-3:focus-visible{
    border: 1px solid;
}

.Refund_button__RhNKQ{
    background: #eb1749;
    width: 100px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
    margin-right: 10px;
}

.Refund_button__RhNKQ:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}

.Refund_componentListCell__lcp3t{
    display: flex;
    align-items: center;
    border: 1px solid;
    margin-bottom: 10px;
    padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/RulePages/Refund/Refund.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,0BAA0B;IAC1B,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.header{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #eb1749;\n    margin: 30px 50px 0px 50px;\n    padding-bottom: 10px;\n}\n\n.body{\n    display: flex;\n    width: 98%;\n    padding: 0 30px 0 30px;\n    flex-direction: column;\n    border: 1px solid #eb1749;\n    border-radius: 0.5rem;\n    margin: 10px 1% 10px 1%;\n}\n\n.p{\n    margin: 20px 0 20px 0;\n}\n\n.pTitle{\n    margin-bottom: 10px;\n}\n\n.searchTextInput{\n    border: 1px solid;\n}\n\n.searchTextInput:focus-visible{\n    border: 1px solid;\n}\n\n\n.textInput{\n    width: 250px;\n    border: 1px solid;\n}\n\n.textInput:focus-visible{\n    border: 1px solid;\n}\n\n.button{\n    background: #eb1749;\n    width: 100px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n    margin-right: 10px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}\n\n.componentListCell{\n    display: flex;\n    align-items: center;\n    border: 1px solid;\n    margin-bottom: 10px;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Refund_container__hqymU`,
	"header": `Refund_header__F2nM6`,
	"body": `Refund_body__3Dyyt`,
	"p": `Refund_p__oumgL`,
	"pTitle": `Refund_pTitle__h9UQ0`,
	"searchTextInput": `Refund_searchTextInput__hwaYD`,
	"textInput": `Refund_textInput__ve+-3`,
	"button": `Refund_button__RhNKQ`,
	"componentListCell": `Refund_componentListCell__lcp3t`
};
export default ___CSS_LOADER_EXPORT___;
