import _ from 'lodash';
import styles from './CourseInfo.module.css';
import CourseModal from '../../Modal/CourseModal';
import { useEffect, useState } from 'react';
import { IoMdRemoveCircle } from 'react-icons/io';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

interface ImageFile {
  fileName: string,
  base64Data: string,
  imgUrl: string | undefined,
}

interface Course {
  day: number | null;
  dayCourseList: DayCourseList[]
}

interface DayCourseList {
  id: number | null,
  sequenceInDay: number | null,
  title: string,
  timeCost: string,
  content: string,
  latitude: string | null,
  longitude: string | null,
  image: ImageFile
}

interface MainInfoProps {
  setCourseProps(course: Course[]):void,
  onAdd(course: Course): void,
  onRemove(index: Number): void,
  courseProps: Course[]
  option: string
}


function CourseInfo({option, courseProps,setCourseProps, onAdd, onRemove} : MainInfoProps) {
  const [courseModalOpen, setCourseModalOpen] = useState<boolean>(false);
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [courseCount, setCourseCount] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editCourseId, setEditCourseId] = useState<number | null>(0);
  const [dayCourse, setDayCourse] = useState<DayCourseList[]>([]);

  useEffect(()=>{
    setCourseList(courseProps);
  },[courseProps])

  useEffect(() => {
    setCourseCount(courseList.length);
  }, [courseList])

  const toggleCourseModal = () => {
    setEditCourseId(null)
    setCourseModalOpen((prev) => !prev);
    setIsEdit(false);
  }
  const handleCourseModal = (course:Course, index: number) => {
    if(isEdit){
      console.log(course)
      let filteredData = _.slice(courseProps);
      filteredData.splice(index, 1, course);
      filteredData = _.sortBy(filteredData,"sequenceId")
      console.log(filteredData)
      setCourseList(filteredData);
      setEditCourseId(null)
      setCourseModalOpen(!courseModalOpen)
      setIsEdit(!isEdit)
      setCourseProps(filteredData)
    }else{
      toggleCourseModal();
      setCourseList((prev) => [...prev, course]);
      setCourseCount((prev) => prev + 1);
      onAdd(course);
    }
  }
  const removeCourse = (index: number) => {
    let filteredData = _.slice(courseProps);
    filteredData.splice(index, 1);
    setCourseList(filteredData);
    setCourseCount((prev) => prev - 1);
    onRemove(index);
  }

  const handleItemReorder = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const dragIndex = source.index;
    const hoverIndex = destination.index;

    let reorderedItems = [...courseList];
    const draggedItem = reorderedItems[dragIndex];

    // 순서 변경
    reorderedItems.splice(dragIndex, 1);
    reorderedItems.splice(hoverIndex, 0, draggedItem);

    reorderedItems = updateSequenceIds(reorderedItems)
    setCourseList(reorderedItems);
    setCourseProps(reorderedItems)
  };
  const updateSequenceIds = (corses:any) => {
    let tourCourses =corses
    for (let index = 0; index < tourCourses.length; index++) {
        tourCourses[index].sequenceId = index + 1;
    }
    return tourCourses
  }
  const editCourse =(id:number | null)=>{
    setEditCourseId(id)
    setCourseModalOpen(true)
    setIsEdit(true)
  }
  // useEffect(()=>{
  //   console.log(editCourseId)
  // },[editCourseId])
  return (
  <div className={`${styles.container} ${styles.course}`}>
      <div>
        <span className={styles.title}>코스 관리</span>
        <button className={styles.addBtn} onClick={toggleCourseModal}>추가하기</button>
        {courseModalOpen && <CourseModal onSave={handleCourseModal} onToggle={toggleCourseModal} courseCount={courseCount} isEdit={isEdit} editCourseId={editCourseId} courseProps={courseProps}/>}
      </div>
      <div className={`${styles.status} ${styles.course}`} style={{overflow:"auto"}}>
                  <div className="component-check" >
                    {courseList.map((course, index) => (
                      <div className="component-check" key={`${course}-${index}`}>
                        {
                          <div className={styles.courseBox}>
                            <div>
                              {course.day}일차
                            </div>            
                            <button className={styles.removeBtn} onClick={() => removeCourse(index)}><IoMdRemoveCircle/></button>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "70px"}}>
                              {course.dayCourseList.map((courseOfDay, index) => (
                                <div className={styles.courseWrapper} key={`${course}-${index}-${courseOfDay}-${index}`}>
                                  <div className={styles.courseImg}>
                                    <img className={styles.courseImg} src={courseOfDay.image.imgUrl ? courseOfDay.image.imgUrl: courseOfDay.image.base64Data} alt="Course" />
                                  </div>
                                  <div className={styles.courseContentWrapper}>
                                    <div className={styles.courseLabel}>
                                      제목 : {courseOfDay.title}
                                    </div>
                                    <div className={styles.courseLabel}>
                                      소요시간 : {courseOfDay.timeCost}
                                    </div>
                                    <div className={styles.courseLabel}>
                                      내용 : {courseOfDay.content}
                                    </div>
                                    <div className={styles.courseLabel}>
                                      위도 : {courseOfDay.latitude}
                                    </div>
                                    <div className={styles.courseLabel}>
                                      경도 : {courseOfDay.longitude}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                              <button className={styles.modifyBtn} style={{marginTop:12,cursor:"pointer"}} onClick={()=>{
                                editCourse(index)
                              }}>수정</button>
                            </div>
                          </div>
                        }
                      </div>
                    ))}
                  </div>
        {/* {renderCourseList()} */}
      </div>
  </div>
  );
}



export default CourseInfo;