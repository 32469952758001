// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainImage_container__3dZds {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 60px;
}

.MainImage_container__3dZds.MainImage_mainImg__tWtO4{
  display: flex;
  min-height: 300px;
}

.MainImage_title__X9Db0 {
  padding-right: 10px;
  white-space: nowrap;
}

 .MainImage_mainImgInput__s41Jr {
  padding: 2px;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-decoration: none;
  border: none;
}

.MainImage_mainImgList__szEKl {
  display: flex;
  overflow: auto;
}

.MainImage_renderedImg__fY-mE {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.MainImage_img__c-YfO {
  width: 320px;
}

.MainImage_removeBtn__fWSB1 {
  position: absolute;
  right: 0;
  font-size: 20px;
  color: red;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/ProductForm/MainImage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;CAEC;EACC,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,eAAe;EACf,UAAU;EACV,6BAA6B;AAC/B","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-top: 20px;\n  padding-bottom: 10px;\n  padding-left: 60px;\n  padding-right: 60px;\n}\n\n.container.mainImg{\n  display: flex;\n  min-height: 300px;\n}\n\n.title {\n  padding-right: 10px;\n  white-space: nowrap;\n}\n\n .mainImgInput {\n  padding: 2px;\n  width: fit-content;\n  height: fit-content;\n  text-decoration: none;\n  border: none;\n}\n\n.mainImgList {\n  display: flex;\n  overflow: auto;\n}\n\n.renderedImg {\n  position: relative;\n  width: fit-content;\n}\n\n.img {\n  width: 320px;\n}\n\n.removeBtn {\n  position: absolute;\n  right: 0;\n  font-size: 20px;\n  color: red;\n  background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainImage_container__3dZds`,
	"mainImg": `MainImage_mainImg__tWtO4`,
	"title": `MainImage_title__X9Db0`,
	"mainImgInput": `MainImage_mainImgInput__s41Jr`,
	"mainImgList": `MainImage_mainImgList__szEKl`,
	"renderedImg": `MainImage_renderedImg__fY-mE`,
	"img": `MainImage_img__c-YfO`,
	"removeBtn": `MainImage_removeBtn__fWSB1`
};
export default ___CSS_LOADER_EXPORT___;
