import React, {useEffect, useState} from "react";
import Table from "../../../components/Table/Table";
import styles from './Refund.module.css'
import { RefundLogic } from '../../../logics/RefundLogic';
import {useNavigate} from "react-router-dom";

// To Extract
// API 확정되면, Extract
const RefundTableColumns = [
	{
		Header: 'ID',
		accessor: 'id'
	},
	{
		Header: '타입',
		accessor: 'type'
	},
	{
		Header: '내용',
		accessor: 'content'
	},
];

const Refund = () => {
	
	const [refundList, setRefundList] = useState<any>([]);

  const navigate = useNavigate();
	
	useEffect(() => {
		
		(async () => {
			const response  = await RefundLogic.getComponentList();
			setRefundList(response);
		})()
		
	}, []);
	
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h2> 환불 약관 관리 </h2>

        		<div>
					<button className={styles.button}
							onClick={() => navigate("/rules/refund/create")}
					>
						생성하기
					</button>
				</div>
			</div>
			
			<div style={{paddingTop: 30}}>
				<Table route={"refund"} columns={RefundTableColumns} data={refundList}/>
			</div>
		
		</div>
	)
}

export default Refund
