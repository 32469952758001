// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Terms_container__lMDiH {
    display: flex;
    flex-direction: column;
}

.Terms_header__EFY40{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eb1749;
    margin: 30px 50px 0px 50px;
    padding-bottom: 10px;
}

.Terms_body__jOHgA{
    display: flex;
    width: 98%;
    padding: 0 30px 0 30px;
    flex-direction: column;
    border: 1px solid #eb1749;
    border-radius: 0.5rem;
    margin: 10px 1% 10px 1%;
}

.Terms_p__9M9O\\+{
    margin: 20px 0 20px 0;
}

.Terms_pTitle__4Muva{
    margin-bottom: 10px;
}

.Terms_searchTextInput__2BE5J{
    border: 1px solid;
}

.Terms_searchTextInput__2BE5J:focus-visible{
    border: 1px solid;
}


.Terms_textInput__cCFoT{
    width: 250px;
    border: 1px solid;
}

.Terms_textInput__cCFoT:focus-visible{
    border: 1px solid;
}

.Terms_button__zBs1N{
    background: #eb1749;
    width: 100px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
    margin-right: 10px;
}

.Terms_button__zBs1N:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}

.Terms_componentListCell__iH8EK{
    display: flex;
    align-items: center;
    border: 1px solid;
    margin-bottom: 10px;
    padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/RulePages/Terms/Terms.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,0BAA0B;IAC1B,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.header{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #eb1749;\n    margin: 30px 50px 0px 50px;\n    padding-bottom: 10px;\n}\n\n.body{\n    display: flex;\n    width: 98%;\n    padding: 0 30px 0 30px;\n    flex-direction: column;\n    border: 1px solid #eb1749;\n    border-radius: 0.5rem;\n    margin: 10px 1% 10px 1%;\n}\n\n.p{\n    margin: 20px 0 20px 0;\n}\n\n.pTitle{\n    margin-bottom: 10px;\n}\n\n.searchTextInput{\n    border: 1px solid;\n}\n\n.searchTextInput:focus-visible{\n    border: 1px solid;\n}\n\n\n.textInput{\n    width: 250px;\n    border: 1px solid;\n}\n\n.textInput:focus-visible{\n    border: 1px solid;\n}\n\n.button{\n    background: #eb1749;\n    width: 100px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n    margin-right: 10px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}\n\n.componentListCell{\n    display: flex;\n    align-items: center;\n    border: 1px solid;\n    margin-bottom: 10px;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Terms_container__lMDiH`,
	"header": `Terms_header__EFY40`,
	"body": `Terms_body__jOHgA`,
	"p": `Terms_p__9M9O+`,
	"pTitle": `Terms_pTitle__4Muva`,
	"searchTextInput": `Terms_searchTextInput__2BE5J`,
	"textInput": `Terms_textInput__cCFoT`,
	"button": `Terms_button__zBs1N`,
	"componentListCell": `Terms_componentListCell__iH8EK`
};
export default ___CSS_LOADER_EXPORT___;
