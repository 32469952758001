import ProductSearch from '../components/Product/ProductSearch/ProductSearch';

function ProductDelete() {
  return (
    <div>
      <ProductSearch isDeleting={true}/>
    </div>
  );
}

export default ProductDelete;