import { useEffect, useState } from "react";
import Style from "./PartnerModal.module.css";
import axios from "axios";

export default function PartnerModal({ getPartnerInfo, setAllPartner }) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState("");
    const [link, setLink] = useState("");
    const [partnerImg, setpartnerImg] = useState();
    const [fileName, setFileName] = useState("");

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setName("");
        setLink("");
        setFileName("");
        setpartnerImg(null);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setpartnerImg(event.target.result);
            };
            reader.readAsDataURL(file);
            setFileName(file.name);
        }
    };

    useEffect(() => {
        getPartnerInfo(setAllPartner);
    }, [isOpen]);

    // partner 정보 post
    const handleAddPartner = () => {
        if (name && link && fileName && partnerImg) {
            axios
                .post(`${process.env.REACT_APP_AMUSE_API}/api/v1/partners`, {
                    name: name,
                    link: link,
                    imgInfo: {
                        fileName: fileName,
                        base64Data: partnerImg,
                    },
                })
                .then((res) => {
                    console.log(res);
                    closeModal();
                })
                .catch((err) => console.log(err));
        } else {
            alert("정보를 입력해주세요.")
        }
    };

    return (
        <div>
            <button onClick={openModal} className={Style["addBtn"]}>추가하기</button>
            {isOpen && (
                <div className={Style["modal"]}>
                    <div className={Style["modalContent"]}>
                        <h2 style={{ marginBottom: "12px" }}>협력업체 추가하기</h2>
                        <div className={Style["infoBox"]}>
                            <div className={Style["inputBox"]}>
                                <input value={name} onChange={(e) => setName(e.target.value)} placeholder="이름" />
                                <input value={link} onChange={(e) => setLink(e.target.value)} placeholder="홈페이지 주소" />
                                <input type="file" accept="image/*" onChange={handleFileChange} />
                            </div>
                            {partnerImg && (
                                <div>
                                    <img
                                        src={partnerImg}
                                        alt="Selected"
                                        style={{ width: "150px", height: "150px", margin: "8px"}}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={Style["btnBox"]}>
                            <button className={Style["modalBtn"]} onClick={closeModal}>닫기</button>
                            <button className={Style["modalBtn"]} onClick={handleAddPartner}>추가하기</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
