// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdRegister_container__Cf\\+Co {
    display: flex;
    flex-direction: column;
}

.AdRegister_body__GCtfA{
    display: flex;
    width: 98%;
    padding: 0 30px 0 30px;
    flex-direction: column;
    border: 1px solid #eb1749;
    border-radius: 0.5rem;
    margin: 10px 1% 10px 1%;
}

.AdRegister_p__ayqcg{
    margin: 20px 0 20px 0;
}

.AdRegister_pTitle__Rem0T{
    margin-bottom: 10px;
}

.AdRegister_showDatePickerBtn__hrm1q{
    margin: 0 10px 0 10px;
    width: 100px;
    border: 1px solid ;
}

.AdRegister_categoryList__40KDW{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
}


.AdRegister_textInput__pc1Li{
    width: 250px;
    border: 1px solid;
}

.AdRegister_textInput__pc1Li:focus-visible{
    border: 1px solid;
}

.AdRegister_button__tQ6io{
    background: #eb1749;
    width: 80px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
}

.AdRegister_button__tQ6io:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}
`, "",{"version":3,"sources":["webpack://./src/components/Ad/AdRegister.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.body{\n    display: flex;\n    width: 98%;\n    padding: 0 30px 0 30px;\n    flex-direction: column;\n    border: 1px solid #eb1749;\n    border-radius: 0.5rem;\n    margin: 10px 1% 10px 1%;\n}\n\n.p{\n    margin: 20px 0 20px 0;\n}\n\n.pTitle{\n    margin-bottom: 10px;\n}\n\n.showDatePickerBtn{\n    margin: 0 10px 0 10px;\n    width: 100px;\n    border: 1px solid ;\n}\n\n.categoryList{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    margin-top: 5px;\n}\n\n\n.textInput{\n    width: 250px;\n    border: 1px solid;\n}\n\n.textInput:focus-visible{\n    border: 1px solid;\n}\n\n.button{\n    background: #eb1749;\n    width: 80px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AdRegister_container__Cf+Co`,
	"body": `AdRegister_body__GCtfA`,
	"p": `AdRegister_p__ayqcg`,
	"pTitle": `AdRegister_pTitle__Rem0T`,
	"showDatePickerBtn": `AdRegister_showDatePickerBtn__hrm1q`,
	"categoryList": `AdRegister_categoryList__40KDW`,
	"textInput": `AdRegister_textInput__pc1Li`,
	"button": `AdRegister_button__tQ6io`
};
export default ___CSS_LOADER_EXPORT___;
