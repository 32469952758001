// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoticeDetail_container__hDLgN {
    display: flex;
    flex-direction: column;
}

.NoticeDetail_body__bYs7h{
    display: flex;
    width: 98%;
    padding: 0 30px 0 30px;
    flex-direction: column;
    border: 1px solid #eb1749;
    border-radius: 0.5rem;
    margin: 10px 1% 10px 1%;
}

.NoticeDetail_p__E9MpS{
    margin: 20px 0 20px 0;
}

.NoticeDetail_pTitle__ToTAF{
    margin-bottom: 10px;
}

.NoticeDetail_showDatePickerBtn__waIS4{
    margin: 0 10px 0 10px;
    width: 100px;
    border: 1px solid ;
}

.NoticeDetail_radioContainer__aX7n9{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.NoticeDetail_textInput__AUKyA{
    width: 250px;
    border: 1px solid;
}

.NoticeDetail_textInput__AUKyA:focus-visible{
    border: 1px solid;
}

.NoticeDetail_button__239Ec{
    background: #eb1749;
    width: 80px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
}

.NoticeDetail_button__239Ec:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notice/NoticeDetail.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.body{\n    display: flex;\n    width: 98%;\n    padding: 0 30px 0 30px;\n    flex-direction: column;\n    border: 1px solid #eb1749;\n    border-radius: 0.5rem;\n    margin: 10px 1% 10px 1%;\n}\n\n.p{\n    margin: 20px 0 20px 0;\n}\n\n.pTitle{\n    margin-bottom: 10px;\n}\n\n.showDatePickerBtn{\n    margin: 0 10px 0 10px;\n    width: 100px;\n    border: 1px solid ;\n}\n\n.radioContainer{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n\n.textInput{\n    width: 250px;\n    border: 1px solid;\n}\n\n.textInput:focus-visible{\n    border: 1px solid;\n}\n\n.button{\n    background: #eb1749;\n    width: 80px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NoticeDetail_container__hDLgN`,
	"body": `NoticeDetail_body__bYs7h`,
	"p": `NoticeDetail_p__E9MpS`,
	"pTitle": `NoticeDetail_pTitle__ToTAF`,
	"showDatePickerBtn": `NoticeDetail_showDatePickerBtn__waIS4`,
	"radioContainer": `NoticeDetail_radioContainer__aX7n9`,
	"textInput": `NoticeDetail_textInput__AUKyA`,
	"button": `NoticeDetail_button__239Ec`
};
export default ___CSS_LOADER_EXPORT___;
