import _ from 'lodash';
import { useEffect, useState } from 'react';
import styles from './TicketInfo.module.css';
import TicketModal from '../../Modal/TicketModal';
import { IoMdRemoveCircle } from 'react-icons/io';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

type Price = {
  startDate: string,
  endDate: string,
  quantity: string,
  weekdayPrices: {
    [key: string]: string
  }
}

interface Ticket {
  id: number | null,
  title: string,
  content: string,
  count: number | null,
  priceList: Price[]
}

interface TicketInfoProps {
  setTicketProps(ticket: Ticket[]):void,
  onAdd(ticket: Ticket): void,
  onRemove(ticket: Ticket): void,
  ticketProps: Ticket[]
}

function TicketInfo({ ticketProps, setTicketProps, onAdd, onRemove }: TicketInfoProps) {

  const [ticketList, setTicketList] = useState<Ticket[]>([]);
  const [ticketModalOpen, setTicketModalOpen] = useState<boolean>(false);
  
  // ticket 수정을 위해 필요한 state
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editTicketId, setEditTicketId] = useState<number | null>(0);

  useEffect(() => {
    setTicketList(ticketProps);
  }, [ticketProps]);

  const toggleTicketModal = () => {
    setTicketModalOpen((prev) => !prev);
    setIsEdit(false);
  }
  const handleTicketModal = (ticket: Ticket, index: number) => {
    // toggleTicketModal();
    // setTicketList((prev) => [...prev, ticket]);
    // onAdd(ticket);
    if(isEdit){
      console.log(isEdit)
      let filteredData = _.slice(ticketProps);
      filteredData.splice(index, 1, ticket);
      filteredData = _.sortBy(filteredData,"sequenceId")
      console.log(filteredData)
      setTicketList(filteredData);
      setEditTicketId(null)
      setTicketModalOpen(!ticketModalOpen)
      setIsEdit(!isEdit)
      setTicketProps(filteredData)
    }else{
      toggleTicketModal();
      setTicketList((prev) => [...prev, ticket]);
      onAdd(ticket);
    }
  }

  const removeTicket = (selectedTicket: Ticket) => {
    setTicketList((prevTickets) => prevTickets.filter((ticket) => ticket.title !== selectedTicket.title));
    onRemove(selectedTicket);
  }

  const handleItemReorder = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const dragIndex = source.index;
    const hoverIndex = destination.index;

    let reorderedItems = [...ticketList];
    const draggedItem = reorderedItems[dragIndex];

    // 순서 변경
    reorderedItems.splice(dragIndex, 1);
    reorderedItems.splice(hoverIndex, 0, draggedItem);

    reorderedItems = updateSequenceIds(reorderedItems)
    setTicketList(reorderedItems);
    setTicketProps(reorderedItems);
  };

  const updateSequenceIds = (corses:any) => {
    let tourCourses = corses
    for (let index = 0; index < tourCourses.length; index++) {
        tourCourses[index].sequenceNum = index + 1;
    }
    return tourCourses
  }

  const editTicket = (id:number | null) => {
    setEditTicketId(id)
    setTicketModalOpen(true)
    setIsEdit(true)
  }

  return (
    <div className={`${styles.container} ${styles.ticket}`}>
      <div>
        <span className={styles.title}>티켓 관리</span>
        <button className={styles.addBtn} onClick={toggleTicketModal}>추가하기</button>
        {ticketModalOpen && <TicketModal onSave={handleTicketModal} onToggle={toggleTicketModal} isEdit={isEdit} editTicketId={editTicketId} ticketProps={ticketProps}/>}
      </div>
      <DragDropContext onDragEnd={handleItemReorder}>
        <Droppable droppableId="ticketList">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {ticketList.map((ticket, index) => (
                <Draggable key={ticket.title} draggableId={ticket.title} index={index}>
                  {(provided) => (
                    <li
                      className={styles.ticketList}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <button className={styles.modifyBtn} onClick={() => {
                        editTicket(index)
                      }}>
                        수정
                      </button>
                      <button className={styles.removeBtn} onClick={() => removeTicket(ticket)}>
                        <IoMdRemoveCircle />
                      </button>
                      <p className={styles.ticketLabel}>티켓 제목</p>
                      <p className={styles.ticketTitle}>{ticket.title}</p>
                      <p className={styles.ticketLabel}>티켓 설명</p>
                      <p className={styles.ticketContent}>{ticket.content}</p>
                      <ul>
                        <p className={styles.ticketLabel}>1인당 티켓 가격</p>
                        {ticket.priceList.map((price) => (
                          <li className={styles.ticketPriceList} key={price.startDate}>
                            <div className={styles.priceStatusDate}>
                              <p>시작일</p>
                              <span>{price.startDate}</span>
                              <p>종료일</p>
                              <span>{price.endDate}</span>
                            </div>
                            <div className={styles.priceStatusWeekDayPrice}>
                              {Object.entries(price.weekdayPrices).map(([weekday, weekdayPrice]) => (
                                <div key={weekday} className={styles.weekdayPriceStatus}>
                                  <p>{weekday}</p>
                                  <span>{weekdayPrice}</span>
                                </div>
                              ))}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default TicketInfo;