// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
  border-radius: var(--border-radius);
}

input {
  border-radius: var(--border-radius);
}

.ProductSearch_container__dcYgy {
  background-color: yellow;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.ProductSearch_category__lp4rH {
  display: flex;
  align-items: center;
}

.ProductSearch_code__vaMrN {
  display: flex;
  align-items: center;
}

.ProductSearch_title__kTsYM { 
  padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/ProductSearch/ProductSearch.module.css"],"names":[],"mappings":"AAGA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;;EAEnB,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@import '../../../App.module.css';\n\n\nselect {\n  border-radius: var(--border-radius);\n}\n\ninput {\n  border-radius: var(--border-radius);\n}\n\n.container {\n  background-color: yellow;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  \n  padding-top: 40px;\n  padding-bottom: 40px;\n  padding-left: 10px;\n  padding-right: 10px;\n}\n\n.category {\n  display: flex;\n  align-items: center;\n}\n\n.code {\n  display: flex;\n  align-items: center;\n}\n\n.title { \n  padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProductSearch_container__dcYgy`,
	"category": `ProductSearch_category__lp4rH`,
	"code": `ProductSearch_code__vaMrN`,
	"title": `ProductSearch_title__kTsYM`
};
export default ___CSS_LOADER_EXPORT___;
