import React, { useState, useEffect } from "react";
import Style from "./FooterEdit.module.css";
import styled from "styled-components";
import MainMoreAbout from "./MainMoreAbout/MainMoreAbout";
import QuillEditor from "./QuillEditor";
import axios from "axios";
import Table from "../../components/Table/Table";
import ScrollTable from "./ScrollTable";
import { PartnerTableCloumns } from "./PartnerTableColumns";
import PartnerModal from "./PartnerModal";

type HTML = string;

type FooterInfo = {
    footerInfo: string;
}

interface PartnerInfo {
    id: number;
    name: string;
    link: string;
    img: string;
}


export const getPartnerInfo = (setAllPartner: React.Dispatch<React.SetStateAction<PartnerInfo[]>>) => {
    axios
        .get(`${process.env.REACT_APP_AMUSE_API}/api/v1/partners`)
        .then((response) => {
            console.log(response);
            setAllPartner(response.data.data.partnerInfoList);
        })
        .catch((error) => {
            console.log(error);
        });
}


function FooterEdit() {
    const [footerInfo, setFooterInfo] = useState<HTML>("");
    const handleFooterInfo = (html: HTML) => {
        setFooterInfo(html);
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_AMUSE_API}/main/footer`)
            .then((response) => {
                setFooterInfo(response.data.data.content);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // footer 내용 post
    const handleSaveFooter = async () => {
        if (footerInfo) {
            axios
                .post(`${process.env.REACT_APP_AMUSE_API}/main/footer`, {content: footerInfo})
                .then((response) => {
                    alert("저장되었습니다.");
                })
                .catch((error) => {
                    console.log(error);
                    alert("저장되지 않았습니다.");
                });
        }
    };

    // 파트너 정보 get
    const [allPartner, setAllPartner] = useState<PartnerInfo[]>([]);
    useEffect(() => {
        getPartnerInfo(setAllPartner);
    }, []);


    return (
        <div className={Style["footer"]}>
            <div className={Style["bottom"]}>
                <MainMoreAbout />
                <div className={Style["editBox"]}>
                    <QuillEditor htmlProps={footerInfo} onChange={handleFooterInfo} />
                    {/* <div className={Style["docsBtn"]}>
                        <TermsBtn href="">이용약관</TermsBtn>
                        <PrivacyBtn href="">개인정보취급정책</PrivacyBtn>
                    </div> */}

                    <div className={Style["partnerBox"]}>
                        {allPartner && (
                            <ScrollTable columns={PartnerTableCloumns(setAllPartner)} data={allPartner} />
                        )}
                        <PartnerModal getPartnerInfo={getPartnerInfo} setAllPartner={setAllPartner}/>
                    </div>
                </div>
            </div>

            <button className={Style["saveBtn"]} onClick={handleSaveFooter}>저장하기</button>
        </div>
    );
}

export default FooterEdit;

const TermsBtn = styled.a`
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid #a3a3a3;
    &:hover {
        text-decoration: underline;
    }
`;

const PrivacyBtn = styled.a`
    &:hover {
        text-decoration: underline;
    }
`;