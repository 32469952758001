// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductManage_container__G\\+HAF.ProductManage_productAdmin__9r792 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 83vh;
}

.ProductManage_routeBox__MDgY2 {
  width: 300px;
  height: 300px;
  border: 2px solid var(--color-pink);
  border-radius: var(--border-radius);
  text-align: center;
  line-height: 300px;
  font-size: 20px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Product/ProductManage/ProductManage.module.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mCAAmC;EACnC,mCAAmC;EACnC,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["@import '../../../App.module.css';;\n.container.productAdmin {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  align-items: center;\n  height: 83vh;\n}\n\n.routeBox {\n  width: 300px;\n  height: 300px;\n  border: 2px solid var(--color-pink);\n  border-radius: var(--border-radius);\n  text-align: center;\n  line-height: 300px;\n  font-size: 20px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProductManage_container__G+HAF`,
	"productAdmin": `ProductManage_productAdmin__9r792`,
	"routeBox": `ProductManage_routeBox__MDgY2`
};
export default ___CSS_LOADER_EXPORT___;
