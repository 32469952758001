// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rules_routeBox__KTvMz {
    width: 300px;
    height: 300px;
    border: 2px solid var(--color-pink);
    border-radius: var(--border-radius);
    text-align: center;
    line-height: 300px;
    font-size: 20px;
    font-weight: bold;
}

.Rules_container__Jf7LT {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 83vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/RulePages/Rules.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mCAAmC;IACnC,mCAAmC;IACnC,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".routeBox {\n    width: 300px;\n    height: 300px;\n    border: 2px solid var(--color-pink);\n    border-radius: var(--border-radius);\n    text-align: center;\n    line-height: 300px;\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    height: 83vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"routeBox": `Rules_routeBox__KTvMz`,
	"container": `Rules_container__Jf7LT`
};
export default ___CSS_LOADER_EXPORT___;
