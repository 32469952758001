// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentCreate_container__QrHYc {
    display: flex;
    flex-direction: column;
}

.PaymentCreate_header__kNjQB{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eb1749;
    margin: 30px 50px 0px 50px;
    padding-bottom: 10px;
}

.PaymentCreate_inputWrapper__\\+a1HA {
    padding: 100px 300px 0 300px;
}

.PaymentCreate_selectBox__tAc6l {
    width: 250px;
}

.PaymentCreate_contentInput__j-hEI {
    margin-top: 50px;
}

.PaymentCreate_textArea__UQA30 {
    width: 500px;
    height: 300px;
    margin-top: 24px;
}

.PaymentCreate_button__8dU3U{
    background: #eb1749;
    width: 100px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.PaymentCreate_button__8dU3U:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}`, "",{"version":3,"sources":["webpack://./src/pages/RulePages/Payment/PaymentCreate.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,gCAAgC;IAChC,0BAA0B;IAC1B,oBAAoB;AACxB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.header{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid #eb1749;\n    margin: 30px 50px 0px 50px;\n    padding-bottom: 10px;\n}\n\n.inputWrapper {\n    padding: 100px 300px 0 300px;\n}\n\n.selectBox {\n    width: 250px;\n}\n\n.contentInput {\n    margin-top: 50px;\n}\n\n.textArea {\n    width: 500px;\n    height: 300px;\n    margin-top: 24px;\n}\n\n.button{\n    background: #eb1749;\n    width: 100px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n    margin-right: 10px;\n    margin-top: 20px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PaymentCreate_container__QrHYc`,
	"header": `PaymentCreate_header__kNjQB`,
	"inputWrapper": `PaymentCreate_inputWrapper__+a1HA`,
	"selectBox": `PaymentCreate_selectBox__tAc6l`,
	"contentInput": `PaymentCreate_contentInput__j-hEI`,
	"textArea": `PaymentCreate_textArea__UQA30`,
	"button": `PaymentCreate_button__8dU3U`
};
export default ___CSS_LOADER_EXPORT___;
