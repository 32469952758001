import React, { useState } from "react";
import { useTable } from "react-table";
import "../../components/Table/table.module.css";
import { useNavigate } from "react-router-dom";

interface ScrollTableProps {
    route?: string;
    columns: any[];
    data: any[];
}


const ScrollTable: React.FC<ScrollTableProps> = ({ route = "", columns, data }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();

    const onClickRow = (row: any) => {
        const id = row.original.id;
        if (route !== "") {
            navigate(`/${route}/${id}`);
            return;
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop } = e.currentTarget;
        setScrollPosition(scrollTop);
    };

    return (
        <div style={{ width: "100%", overflow: "auto", height: "365px", border: "1px solid black" }} onScroll={handleScroll}>
            <div className="table">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {rows.map((row: any) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={() => onClickRow(row)}>
                                    {row.cells.map((cell: any) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ScrollTable;
