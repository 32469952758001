const COLOR = {
    FILTER_MAIN: "#717171;",
    BORDER_MAIN: "#78909C",
    TEXT_MAIN: "#4F4F4F",
  
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    RED: "#FF0000",
    YELLOW: "#FFFF00",
    GREEN: "#00FF00",
    PURPLE: "#800080",
    BLUE: "	#0000FF",
    GRAY: "#808080",
    LIGHT_GRAY: "#EEEEEE",
    CYAN_BLUE: "#0066FF",
    MINT: "#3EB489",
    ORANGE: "#FF8000",
    BRAUN:"#7A6443",
    MADARIN: "#FFBA75",
    DARK_ORANGE:"#EC8000",
    INDIGO:"#0A599A",
    DARK_INDIGO:"#004A87",
    MOCCA:"#CBBAA0",
    DARK_GREEN:"#006000",
    LIGHT_GREEN:"rgb(233,255,127)",
    GRAY_GRESS:"#9CCBAB",
    
  };
  
  export default COLOR;