// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* Colors */
  --color-pink: #eb3e6c;
  --color-white: #ffffff;
  --color-light-grey: #f5f5f5;

  /* Border Width */
  --border-width-thick: 4px;
  --border-width-medium: 2.5px;
  --border-width-thin: 1px;

  /* Border Radius */
  --border-radius: 10px;
}

* {
  font-family: "Courier New", Courier, monospace;

  box-sizing: border-box;

  margin: 0;
  padding: 0;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

input {
  height: 34px;
  padding: 8px;
  border: none;
  outline: none;
  box-shadow: none;
}

select {
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  outline: none;
  border: none;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

textarea {
  border: none;
  outline: none;
  resize: none;
  padding: 10px;
}

input,
textarea,
select {
  border: var(--border-width-medium) solid transparent;
}
input:focus,
textarea:focus,
select:focus {
  border: var(--border-width-medium) solid var(--color-pink);
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;EACrB,sBAAsB;EACtB,2BAA2B;;EAE3B,iBAAiB;EACjB,yBAAyB;EACzB,4BAA4B;EAC5B,wBAAwB;;EAExB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,8CAA8C;;EAE9C,sBAAsB;;EAEtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,aAAa;AACf;;AAEA;;;EAGE,oDAAoD;AACtD;AACA;;;EAGE,0DAA0D;AAC5D","sourcesContent":[":root {\n  /* Colors */\n  --color-pink: #eb3e6c;\n  --color-white: #ffffff;\n  --color-light-grey: #f5f5f5;\n\n  /* Border Width */\n  --border-width-thick: 4px;\n  --border-width-medium: 2.5px;\n  --border-width-thin: 1px;\n\n  /* Border Radius */\n  --border-radius: 10px;\n}\n\n* {\n  font-family: \"Courier New\", Courier, monospace;\n\n  box-sizing: border-box;\n\n  margin: 0;\n  padding: 0;\n}\n\nbutton {\n  border: none;\n  outline: none;\n  background-color: transparent;\n  cursor: pointer;\n}\n\ninput {\n  height: 34px;\n  padding: 8px;\n  border: none;\n  outline: none;\n  box-shadow: none;\n}\n\nselect {\n  height: fit-content;\n  padding: 10px;\n  outline: none;\n  border: none;\n  box-sizing: border-box;\n}\n\nul {\n  list-style: none;\n}\n\ntextarea {\n  border: none;\n  outline: none;\n  resize: none;\n  padding: 10px;\n}\n\ninput,\ntextarea,\nselect {\n  border: var(--border-width-medium) solid transparent;\n}\ninput:focus,\ntextarea:focus,\nselect:focus {\n  border: var(--border-width-medium) solid var(--color-pink);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
