import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from "./Rules.module.css";

export default function Rules() {
  
    const navigate = useNavigate();
  
  return (
    <div className={styles.container}>
        <div onClick={() => navigate("/rules/payment")} className={styles.routeBox}>
            결제 방법   
        </div>
        <div onClick={() => navigate("/rules/refund")} className={styles.routeBox}>
            환불 정책
        </div>
        <div onClick={() => navigate("/rules/terms")} className={styles.routeBox}>
            약관 동의
        </div>
    </div>
  )
}
