// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../App.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_logoAndLogin__-TkiV{
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
}

.Header_logo__UsziS {
  width: 200px;
}

.Header_category__JdLhg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  display: flex;
  justify-content: space-between;
  border-bottom: var(--border-width-thin) solid var(--color-light-grey);
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8IAA8I;EAC9I,aAAa;EACb,8BAA8B;EAC9B,qEAAqE;EACrE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":["@import '../../App.module.css';\n\n.logoAndLogin{\n  height: 80px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-weight: bold;\n  padding-top: 20px;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-bottom: 10px;\n}\n\n.logo {\n  width: 200px;\n}\n\n.category {\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;\n  display: flex;\n  justify-content: space-between;\n  border-bottom: var(--border-width-thin) solid var(--color-light-grey);\n  padding-top: 20px;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoAndLogin": `Header_logoAndLogin__-TkiV`,
	"logo": `Header_logo__UsziS`,
	"category": `Header_category__JdLhg`
};
export default ___CSS_LOADER_EXPORT___;
