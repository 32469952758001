// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PartnerModal_addBtn__TYVER {
  width: 100px;
  margin: 10px;
  border-radius: 10px;
  background-color: #eb1749;
  color: #fff;
  font-size: 18px;
}

.PartnerModal_addBtn__TYVER:hover {
  border: 1px solid #eb1749;
  background: #fff;
  color: #eb1749;
}

/* 모달  */
.PartnerModal_modal__--GWK {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.PartnerModal_modalContent__eC7Rg {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.PartnerModal_infoBox__EILOT {
  display: flex;
}

.PartnerModal_inputBox__Xy7Yi {
  display: flex;
  flex-direction: column;
}

.PartnerModal_btnBox__2SWA4 {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.PartnerModal_modalBtn__ZJjOB {
  width: 80px;
  border: 1px solid #eb1749;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.PartnerModal_modalBtn__ZJjOB:hover {
  background: #eb1749;
  color: #fff;
}

.PartnerModal_modalContent__eC7Rg input {
  height: 40px;
  background-color: antiquewhite;
  margin: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/FooterEditingPages/PartnerModal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AAChB;;AAEA,QAAQ;AACR;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,8BAA8B;EAC9B,WAAW;AACb","sourcesContent":[".addBtn {\n  width: 100px;\n  margin: 10px;\n  border-radius: 10px;\n  background-color: #eb1749;\n  color: #fff;\n  font-size: 18px;\n}\n\n.addBtn:hover {\n  border: 1px solid #eb1749;\n  background: #fff;\n  color: #eb1749;\n}\n\n/* 모달  */\n.modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999;\n}\n\n.modalContent {\n  background-color: white;\n  padding: 20px;\n  border-radius: 5px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  display: flex;\n  flex-direction: column;\n}\n\n.infoBox {\n  display: flex;\n}\n\n.inputBox {\n  display: flex;\n  flex-direction: column;\n}\n\n.btnBox {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 15px;\n}\n\n.modalBtn {\n  width: 80px;\n  border: 1px solid #eb1749;\n  border-radius: 8px;\n  cursor: pointer;\n  font-size: 16px;\n}\n\n.modalBtn:hover {\n  background: #eb1749;\n  color: #fff;\n}\n\n.modalContent input {\n  height: 40px;\n  background-color: antiquewhite;\n  margin: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtn": `PartnerModal_addBtn__TYVER`,
	"modal": `PartnerModal_modal__--GWK`,
	"modalContent": `PartnerModal_modalContent__eC7Rg`,
	"infoBox": `PartnerModal_infoBox__EILOT`,
	"inputBox": `PartnerModal_inputBox__Xy7Yi`,
	"btnBox": `PartnerModal_btnBox__2SWA4`,
	"modalBtn": `PartnerModal_modalBtn__ZJjOB`
};
export default ___CSS_LOADER_EXPORT___;
