// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Notice_container__hRTei{
    display: flex;
    flex-direction: column;
}

.Notice_title__J6klb{
    margin-left: 50px;
    margin-top: 30px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #eb1749;
    margin-bottom: 20px;
}

.Notice_button__AZ7jh{
    background: #eb1749;
    width: 80px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
}

.Notice_button__AZ7jh:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notice/Notice.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":["\n\n.container{\n    display: flex;\n    flex-direction: column;\n}\n\n.title{\n    margin-left: 50px;\n    margin-top: 30px;\n    font-weight: bold;\n    padding-bottom: 10px;\n    border-bottom: 1px solid #eb1749;\n    margin-bottom: 20px;\n}\n\n.button{\n    background: #eb1749;\n    width: 80px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Notice_container__hRTei`,
	"title": `Notice_title__J6klb`,
	"button": `Notice_button__AZ7jh`
};
export default ___CSS_LOADER_EXPORT___;
