// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryDetail_container__VLFWM {
    display: flex;
    flex-direction: column;
}

.CategoryDetail_body__pfuqT{
    display: flex;
    width: 98%;
    padding: 0 30px 0 30px;
    flex-direction: column;
    border: 1px solid #eb1749;
    border-radius: 0.5rem;
    margin: 10px 1% 10px 1%;
}

.CategoryDetail_p__ClTld{
    margin: 20px 0 20px 0;
}

.CategoryDetail_pTitle__QOARV{
    margin-bottom: 10px;
}


.CategoryDetail_textInput__ROrgR{
    width: 250px;
    border: 1px solid;
}

.CategoryDetail_textInput__ROrgR:focus-visible{
    border: 1px solid;
}

.CategoryDetail_button__d3uD6{
    background: #eb1749;
    width: 80px;
    height: 30px;
    color: #fff;
    border-radius: 10px;
}

.CategoryDetail_button__d3uD6:hover{
    border: 1px solid #eb1749;
    background: #fff;
    color: #eb1749;
}
`, "",{"version":3,"sources":["webpack://./src/components/Category/CategoryDetail.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n\n.body{\n    display: flex;\n    width: 98%;\n    padding: 0 30px 0 30px;\n    flex-direction: column;\n    border: 1px solid #eb1749;\n    border-radius: 0.5rem;\n    margin: 10px 1% 10px 1%;\n}\n\n.p{\n    margin: 20px 0 20px 0;\n}\n\n.pTitle{\n    margin-bottom: 10px;\n}\n\n\n.textInput{\n    width: 250px;\n    border: 1px solid;\n}\n\n.textInput:focus-visible{\n    border: 1px solid;\n}\n\n.button{\n    background: #eb1749;\n    width: 80px;\n    height: 30px;\n    color: #fff;\n    border-radius: 10px;\n}\n\n.button:hover{\n    border: 1px solid #eb1749;\n    background: #fff;\n    color: #eb1749;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CategoryDetail_container__VLFWM`,
	"body": `CategoryDetail_body__pfuqT`,
	"p": `CategoryDetail_p__ClTld`,
	"pTitle": `CategoryDetail_pTitle__QOARV`,
	"textInput": `CategoryDetail_textInput__ROrgR`,
	"button": `CategoryDetail_button__d3uD6`
};
export default ___CSS_LOADER_EXPORT___;
