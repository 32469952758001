import { useEffect, useMemo, useRef, useState } from "react"
import "react-quill/dist/quill.snow.css"
import ReactQuill, { Quill } from "react-quill"
import { storage } from "../../firebase";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

function QuillEditor({ htmlProps, onChange }) {
    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
        'color',
        'background',
        'float',
        'height',
        'width',
        'size'
    ];

    const quillRef = useRef();

    // Firebase를 활용한 image handler
    const imageHandler = () => {

        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.addEventListener("change", async () => {

            const editor = quillRef.current.getEditor();
            const file = input.files[0];
            const range = editor.getSelection(true);

            try {
                // 파일명을 "image/Date.now()"로 저장
                const storageRef = ref(
                    storage,
                    `image/${Date.now()}`
                );
                // Firebase Method : uploadBytes, getDownloadURL
                await uploadBytes(storageRef, file).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        editor.insertEmbed(range.index, "image", url);
                        editor.setSelection(range.index + 1);
                    });
                });
            } catch (error) {
                console.log(error);
            }
        });
    };

    const modules = useMemo(() => {
        return {
            imageActions: {},
            imageFormats: {},
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, false] }],
                    [{ size: ['small', "regular", "large", "huge"] }],
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }, "link", "image"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }
    }, [])

    return (
        <div style={{ marginTop: "0px", width: "560px", height: "365px", backgroundColor: "white"  }}>
            <ReactQuill
                style={{ width: "560px", height: "300px", backgroundColor: "white" }}
                formats={formats}
                placeholder="Quill Content"
                theme="snow"
                ref={quillRef}
                value={htmlProps}
                onChange={onChange}
                modules={modules}
                preserveWhitespace={true}
            />
        </div>
    )
}
export default QuillEditor