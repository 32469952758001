import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styles from './PaymentDetail.module.css';

export default function PaymentDetail() {

    const { id } = useParams();
    const navigate = useNavigate();

    const [type, setType] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        axios
        .get(`https://amuseapi.wheelgo.net/test/api/payment-method-info/${id}`)
        .then((res) => {
            setType(res.data.data.type);
            setContent(res.data.data.content);
        })
    }, []);

    const handleContentInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(event.target.value);
    }

    const deletePayment =  () => {
        const userConfirmed = window.confirm('정말로 삭제하시겠습니까?');

        // 사용자가 확인을 선택한 경우에만 삭제 요청을 보냄
        if (userConfirmed) {
            axios
            .delete(`https://amuseapi.wheelgo.net/test/api/payment-method-info/${id}`)
            .then((res) => {
                navigate('/rules/payment')
            })
            .catch((err) => {
              console.error('삭제 실패', err);
            });
        }
    }

    const handleEdit = () => {
        const userConfirmed = window.confirm('정말로 수정하시겠습니까?');

        const product = {
            type: type,
            content: content
        };

        // 사용자가 확인을 선택한 경우에만 삭제 요청을 보냄
        if (userConfirmed) {
            axios
            .put(`https://amuseapi.wheelgo.net/test/api/payment-method-info/${id}`, product)
            .then((response) => {
                alert('수정되었습니다.');
                navigate('/rules/payment');
            })
            .catch((error) => {
              console.error('삭제 실패', error);
            });
        }
    }

  return (
    <div className={styles.container}>
        <div className={styles.header}>
		    <h2> 결제 약관 관리 </h2>
		</div>
        <div className={styles.inputWrapper}>
            <select value={type} className={styles.selectBox} disabled>
                <option value="선택">선택</option>
                <option value="Domestic">Domestic</option>
                <option value="Domestic_Hotel">Domestic_Hotel</option>
                <option value="International">International</option>
                <option value="International_Hotel">International_Hotel</option>
            </select>
            <div className={styles.contentInput}>
                <h4>내용</h4>
                <textarea className={styles.textArea} placeholder='내용을 입력해주세요' value={content} onChange={handleContentInput}></textarea>
            </div>
            <button className={styles.button} onClick={handleEdit}>
				수정하기
			</button>
            <button className={styles.button} onClick={deletePayment}>
				삭제하기
			</button>
        </div>
    </div>
  )
}
