import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from "./TermsCreate.module.css";
import axiosInstance,{axiosTokenRefresh} from "../../../services/axiosInstance";
import { useCookies } from "react-cookie";
import axios from 'axios';

export default function TermsCreate() {
  
    const [itemType, setItemType] = useState('선택');
    const [content, setContent] = useState('');
    const [cookies, setCookie] = useCookies(["id"]);
    const navigate = useNavigate();
    const handleItemType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemType(event.target.value);
    };
    const handleContentInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(event.target.value);
    }

    const addRefundRules = async() => {
    
    if (itemType == '선택') {
        alert('Type을 선택해주세요')
        return;
    }

    const product = {
        type: itemType,
        content: content
    };

    await axiosTokenRefresh(cookies.id)
    
    axios
    .post("https://amuseapi.wheelgo.net/test/api/terms-of-service-info", product)
    .then((res) => {
        alert('생성되었습니다.')
        navigate('/rules/terms')
    })
    .catch((err) => {
        console.log(err)
    })
    }
  
  return (
    <div className={styles.container}>
        <div className={styles.header}>
		    <h2> 약관 규정 관리 </h2>
		</div>
        <div className={styles.inputWrapper}>
            <select value={itemType} onChange={handleItemType} className={styles.selectBox}>
                <option value="선택">선택</option>
                <option value="SignUp">SignUp</option>
                <option value="Domestic">Domestic</option>
                <option value="Domestic_Hotel">Domestic_Hotel</option>
                <option value="International">International</option>
                <option value="International_Hotel">International_Hotel</option>
            </select>
            <div className={styles.contentInput}>
                <h4>내용</h4>
                <textarea className={styles.textArea} placeholder='내용을 입력해주세요' value={content} onChange={handleContentInput}></textarea>
            </div>
            <button className={styles.button} onClick={addRefundRules}>
				추가하기
			</button>   
        </div>
    </div>
  )
}
