// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainMoreAbout_middle__HP8e6 {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
}

.MainMoreAbout_sns__ltuZP {
  width: 30%;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainMoreAbout_sns__ltuZP img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.MainMoreAbout_logoImgs__VrnJ0 {
  width: 100px;
  float: left;
  margin: auto;
}

.MainMoreAbout_imgContainer__z5vPS {
  display: flex;
  /* justify-content: space-between; */
}

.MainMoreAbout_middle-mobile__76Kuj {
  margin-top: 2rem;
  margin-bottom: 3rem;
  margin-right: auto;
}

.MainMoreAbout_sns-mobile__mivE2 {
  width: 80%;
  margin: 10px auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainMoreAbout_sns-mobile__mivE2 img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FooterEditingPages/MainMoreAbout/MainMoreAbout.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,mBAAmB;EACnB,+CAA+C;EAC/C,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;EACnB,+CAA+C;EAC/C,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".middle {\n  text-align: center;\n  display: flex;\n  justify-content: space-between;\n  margin: 1rem auto;\n}\n\n.sns {\n  width: 30%;\n  background-color: #fff;\n  border-radius: 12px;\n  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.sns img {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n}\n\n.logoImgs {\n  width: 100px;\n  float: left;\n  margin: auto;\n}\n\n.imgContainer {\n  display: flex;\n  /* justify-content: space-between; */\n}\n\n.middle-mobile {\n  margin-top: 2rem;\n  margin-bottom: 3rem;\n  margin-right: auto;\n}\n\n.sns-mobile {\n  width: 80%;\n  margin: 10px auto;\n  background-color: #fff;\n  border-radius: 12px;\n  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.sns-mobile img {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"middle": `MainMoreAbout_middle__HP8e6`,
	"sns": `MainMoreAbout_sns__ltuZP`,
	"logoImgs": `MainMoreAbout_logoImgs__VrnJ0`,
	"imgContainer": `MainMoreAbout_imgContainer__z5vPS`,
	"middle-mobile": `MainMoreAbout_middle-mobile__76Kuj`,
	"sns-mobile": `MainMoreAbout_sns-mobile__mivE2`
};
export default ___CSS_LOADER_EXPORT___;
