import React, {useEffect, useState} from "react";
import Table from "../../../components/Table/Table";
import styles from './Payment.module.css'
import { PaymentLogic } from '../../../logics/PaymentLogic';
import {useNavigate} from "react-router-dom";

// To Extract
// API 확정되면, Extract
const PaymentTableColumns = [
	{
		Header: 'ID',
		accessor: 'id'
	},
	{
		Header: '타입',
		accessor: 'type'
	},
	{
		Header: '내용',
		accessor: 'content'
	},
];

const Payment = () => {
	
	const [paymentList, setPaymentList] = useState<any>([]);

  const navigate = useNavigate();
	
	useEffect(() => {
		
		(async () => {
			const response  = await PaymentLogic.getComponentList();
			setPaymentList(response);
		})()
		
	}, []);
	
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h2> 결제 약관 관리 </h2>

        		<div>
					<button className={styles.button}
							onClick={() => navigate("/rules/payment/create")}
					>
						생성하기
					</button>
				</div>
			</div>
			
			<div style={{paddingTop: 30}}>
				<Table route={"payment"} columns={PaymentTableColumns} data={paymentList}/>
			</div>
		
		</div>
	)
}

export default Payment
