import ProductForm from '../components/Product/ProductForm/ProductForm';
import ProductSearch from '../components/Product/ProductSearch/ProductSearch';

function ProductCreate() {
  return (
    <div>
      <ProductForm/>
    </div>
  );
}

export default ProductCreate;