import { useEffect, useState } from 'react';
import styles from './CourseModal.module.css';
import _ from 'lodash';
import { IoMdRemoveCircle } from 'react-icons/io';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

interface ImageFile {
  fileName: string,
  base64Data: string
  imgUrl:string | undefined
}

interface Course {
  day: number | null;
  dayCourseList: DayCourseList[]
}

interface DayCourseList {
  id: number | null,
  sequenceInDay: number | null,
  title: string,
  timeCost: string,
  content: string,
  latitude: string | null,
  longitude: string | null,
  image: ImageFile
}

type MordalProps = {
  onSave(course:Course, index: number | null): void,
  onToggle(): void,
  courseCount: number,
  isEdit:boolean,
  editCourseId:number|null,
  courseProps:any
};

function CourseModal({courseCount, onSave, onToggle, isEdit, editCourseId, courseProps}: MordalProps) {
  const [sequenceId, setSequenceId] = useState<number>(0);
  useEffect(() => {
    setSequenceId(courseCount);
  },[courseCount])
  const [title, setTitle] = useState<string>('');
  const [timeCost, setTimeCost] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [dayCourse, setDayCourse] = useState<DayCourseList[]>([]);

  const [content, setContent] = useState<string>('');
  const [image, setImage] = useState<ImageFile | undefined>();
  
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const handleContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.currentTarget.value);
  };

  const handleTimeCost = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.currentTarget.value
    setTimeCost(input);
  };

  const handleLatitude = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value.replace(/[^0-9.]/g,"");
    setLatitude(input);
  }

  const handleLongitude = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value.replace(/[^0-9.]/g,"");
    setLongitude(input);
  }

  const handleImg = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const imageFile = await new Promise <ImageFile>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({fileName: file.name, base64Data: reader.result as string,imgUrl: undefined});
          };
          reader.readAsDataURL(file);
        });
        setImage(imageFile);
      }
      catch(error) {
        console.error("Error reading the image file: ", error);
        setImage(undefined);
      }
    } 
    else {
      setImage(undefined);
    }
  }

  const [day, setDay] = useState<number>(1);

  const handleDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDay(Number(event.target.value));
  }
  
  const handleSave = () => {
    if(dayCourse.length > 0){
      let courseId = null
      if(isEdit){courseId = editCourseId}
      const course:Course = {
        day: day,
        dayCourseList: dayCourse
      };
      onSave(course, editCourseId);
    }
  };
  useEffect(()=>{
    if(isEdit && editCourseId != null){
      let item = courseProps[editCourseId]
      // let item = _.find(courseProps,{day:editCourseId})
      console.log(item)
      setDayCourse(item.dayCourseList)
    }
  },[editCourseId])

  // 하나의 날짜 안에 각각의 코스들을 추가함.
  const handleAdd = () => {
    if(title.length > 0 && content.length && timeCost && image){
      let courseId = null
      if(isEdit){courseId = editCourseId}
      const courseOfDay:DayCourseList = {
        id: courseId,
        sequenceInDay: null,
        title: title,
        timeCost: timeCost,
        content: content,
        latitude: latitude,
        longitude: longitude,
        image: image
      }
      const newDayCourse = [...dayCourse, courseOfDay];
      setDayCourse(newDayCourse);
    }
    setTitle("")
    setTimeCost("")
    setContent("")
    setLatitude("")
    setLongitude("")
    setImage(undefined);
  }

  // 날짜의 각 항목들을 삭제하는 함수
  const removeEachCourse = (index: number) => {
    let filteredData = _.slice(dayCourse);
    filteredData.splice(index, 1);
    setDayCourse(filteredData);

    // let newDayCourse = dayCourse.splice(index, 1);
    // setDayCourse(newDayCourse);
  }

  const handleItemReorder = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const dragIndex = source.index;
    const hoverIndex = destination.index;

    let reorderedItems = [...dayCourse];
    const draggedItem = reorderedItems[dragIndex];

    // 순서 변경
    reorderedItems.splice(dragIndex, 1);
    reorderedItems.splice(hoverIndex, 0, draggedItem);

    reorderedItems = updateSequenceIds(reorderedItems);
    setDayCourse(reorderedItems);
  };

  const updateSequenceIds = (corses:any) => {
    let tourCourses = corses
    for (let index = 0; index < tourCourses.length; index++) {
        tourCourses[index].sequenceInDay = index + 1;
    }
    return tourCourses
  }

  return (
    <>
      <div className={styles.modal}>
        <header className={styles.header}>
          <span className={styles.headerTitle}>코스 관리</span>
          <button className={styles.exitBtn} onClick={onToggle}>나가기</button>
        </header>
        <div className={styles.body}>
          <div className={`${styles.container} ${styles.title}`}>
            <p className={styles.label}>코스 제목</p>
            <input className={`${styles.input} ${styles.title}`} value={title} onChange={handleTitle} type="text" />
          </div>
          <div className={`${styles.container} ${styles.timeCostAndDay}`}>
            <div>
              <p className={styles.label}>코스 소요시간</p>
              <input className={`${styles.input} ${styles.timeCost}`} value={timeCost} onChange={handleTimeCost} type="text" placeholder='코스 소요시간을 입력하세요.' />
            </div>
            <div>
              <p className={styles.label}>시기</p>
              <input className={`${styles.input} ${styles.day}`} value={day} onChange={handleDay} type="text" placeholder='코스 소요시간을 입력하세요.' />
              <span>일차</span>
            </div>
          </div>
          <div className={`${styles.container} ${styles.location}`}>
            <div className={styles.latitude}>
              <p className={styles.label}>위도값</p>
              <input className={`${styles.input} ${styles.latitude}`} value={latitude} onChange={handleLatitude} type="text" placeholder='위도값을 입력하세요.' />
            </div>
            <div className={styles.longitude}>
              <p className={styles.label}>경도값</p>
              <input className={`${styles.input} ${styles.longitude}`} value={longitude} onChange={handleLongitude} type="text" placeholder='경도값을 입력하세요.' />
            </div>
          </div>
          <div className={`${styles.container} ${styles.content}`}>
            <p className={styles.label}>코스 설명</p>
            <textarea className={`${styles.input} ${styles.content}`} value={content} onChange={handleContent}></textarea>
          </div>
          <div className={`${styles.container} ${styles.img}`}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
              <span className={` ${styles.label} ${styles.img}`} style={{display:"flex",alignItems:"center"}}>코스 이미지</span>
              <input className={styles.imgInput} style={{marginBottom:12}} id="imgInput" onChange={handleImg} accept="image/png, image/jpeg" type="file"/>
            </div>
            {image && <img className={styles.currentImg} src={image.base64Data || image.imgUrl} alt="Course" />}
          </div>
          <button className={styles.addBtn} onClick={handleAdd}>추가</button>
          <div className={styles.dayCourseList}>
          <DragDropContext onDragEnd={handleItemReorder}>
            <Droppable droppableId="ticketList">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {dayCourse.map((eachCourse, index) => (
                    <Draggable key={eachCourse.title} draggableId={eachCourse.title} index={index}>
                      {(provided) => (
                        <li className={styles.ticketList}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                          <div key={index} className={styles.eachCourseBox}>
                            <div className={styles.dayContent}>
                              <img src={eachCourse.image.base64Data || eachCourse.image.imgUrl} style={{width: "200px", height: "150px"}}/>
                            </div>
                            <div className={styles.dayContentWrapper}>
                              <div className={styles.dayContent}>
                                제목 : {eachCourse.title}
                              </div>
                              <div className={styles.dayContent}>
                                내용 : {eachCourse.content}
                              </div>
                              <div className={styles.dayContent}>
                                위도 : {eachCourse.latitude}
                              </div>
                              <div className={styles.dayContent}>
                                경도 : {eachCourse.longitude}
                              </div>
                            </div>
                            <button className={styles.removeBtn} onClick={() => {removeEachCourse(index);}}><IoMdRemoveCircle/></button>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          </div>
        </div>
          {isEdit? <button className={styles.saveBtn} onClick={handleSave}>수정</button> : <button className={styles.saveBtn} onClick={handleSave}>저장</button>}
        <div>
        </div>
      </div>
      <div className={styles.backDrop}></div>
    </>
  );
}

export default CourseModal;