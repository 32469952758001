// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_table__2IQ7e {
    text-align: center;
}

table {
    /*font-family: Arial, Helvetica, sans-serif;*/
    border-collapse: collapse;
    /*border-collapse: inherit;*/
    /*border: 1px solid;*/
    width: 100%;
    font-size: 15px;
}

table td, table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

table tr:nth-child(even){
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #f2f2f2;
}

table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #fff;
    color: #000000;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/table.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,6CAA6C;IAC7C,yBAAyB;IACzB,4BAA4B;IAC5B,qBAAqB;IACrB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".table {\n    text-align: center;\n}\n\ntable {\n    /*font-family: Arial, Helvetica, sans-serif;*/\n    border-collapse: collapse;\n    /*border-collapse: inherit;*/\n    /*border: 1px solid;*/\n    width: 100%;\n    font-size: 15px;\n}\n\ntable td, table th {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\ntable tr:nth-child(even){\n    background-color: #f2f2f2;\n}\n\ntable tr:hover {\n    background-color: #f2f2f2;\n}\n\ntable th{\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    background-color: #fff;\n    color: #000000;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `table_table__2IQ7e`
};
export default ___CSS_LOADER_EXPORT___;
